import dynamic from 'next/dynamic'
import Image from 'next/image'

import styles from './hero-background.module.scss'
import { constants } from '@/lib/constants'

const TrustpilotWidget = dynamic(() => import('@/components/atomic/TrustpilotWidget'))

export const HeroBackground = ({ children, isVans }) => {
  return (
    <>
      <div className={`${styles.background} ${isVans ? styles['is-vans'] : styles['is-cars']}`}>
        <div className={styles.top}>
          <Image
            src={`${constants.imageHost.assets}/homepage/top-dash.svg`}
            alt="Top of a dashboard"
            layout="fill"
            priority
          />
        </div>
        {children}
        <div className={styles.bottom}>
          <Image
            src={`${constants.imageHost.assets}/homepage/bottom-dash.svg`}
            alt="Bottom of a dashboard"
            layout="fill"
            priority
          />
        </div>
      </div>
      <div className={styles.widget}>
        <TrustpilotWidget
          template={constants.trustpilot.templates.MICRO_STAR}
          businessUnitId={constants.trustpilot.businessUnitId}
          theme="light"
          link={`${constants.trustpilot.reviewUrl}/${constants.trustpilot.link}`}
          height={26}
        />
      </div>
    </>
  )
}
