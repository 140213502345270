import Image, { ImageProps } from 'next/image'

import styles from './vehicle-image.module.scss'
import { constants } from '@/lib/constants'
import { getVehicleHeroImage } from '@/lib/utilities/vehicle'

export type VehicleImageProps = {
  alt: string
  height: number
  width: number
  imagePath: string
  backupImagePath: string
  priority?: boolean
  quality?: number
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined
  placeholder?: ImageProps['placeholder']
  blurDataURL?: string
  className?: string
  type: 'DEFAULT' | 'GALLERY' | 'BROWSE' | 'HOMEPAGE'
}

export const VehicleImage = ({
  alt,
  height,
  width,
  imagePath,
  backupImagePath,
  priority = false,
  quality = 75,
  layout = undefined,
  placeholder = undefined,
  blurDataURL,
  className = '',
  type = 'DEFAULT',
}: VehicleImageProps) => {
  return (
    <div
      // if backup is required and is sideview then flip
      className={
        type !== 'GALLERY' && type !== 'BROWSE' && type !== 'HOMEPAGE' && imagePath
          ? styles['reverse-image']
          : ''
      }>
      <Image
        src={
          imagePath
            ? `${constants.imageHost.vehicleImage}/${imagePath}`
            : getVehicleHeroImage(backupImagePath)
        }
        alt={alt}
        height={height}
        width={width}
        priority={priority}
        quality={quality}
        layout={layout}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
        className={className}
      />
    </div>
  )
}
