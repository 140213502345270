import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import { FC, ReactNode, RefObject, useEffect } from 'react'
import useSmoothHorizontalScroll from 'use-smooth-horizontal-scroll'

import styles from './carousel.module.scss'
import { Button, ButtonHierarchy } from '@/components/styleguide/Button'
import { InternalLink } from '@/components/styleguide/Link'
import { Size } from '@/types/system'

const BrandsModal = dynamic(() => import('@/components/modals/BrandsModal'), {
  ssr: false,
})

export type CarouselProps = {
  children: ReactNode
  scrollValue: number
  moreLink?: string
  moreLabel?: string
  sectionName: string
  header?: ReactNode
  className?: string
  onCustomButtonClick?: () => void
  carouselClass?: string
  width?: 'fixed' | 'content'
}

export const Carousel = ({
  children,
  scrollValue,
  moreLink,
  moreLabel,
  sectionName,
  header,
  onCustomButtonClick,
  className = '',
  carouselClass = '',
  width = 'fixed',
}: CarouselProps) => {
  const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } =
    useSmoothHorizontalScroll()

  useEffect(() => {
    window.addEventListener('resize', handleScroll)

    return () => window.removeEventListener('resize', handleScroll)
  }, [handleScroll])

  const onBrandsMoreClick = () => {
    NiceModal.show(BrandsModal as FC)
  }

  return (
    <section
      className={`section-${sectionName} ${styles.container} ${className}`}
      data-width={width}
      data-section-name={sectionName}>
      <div className={`${styles.top} ${!header ? styles['no-header'] : ''}`}>
        {header && <div className={styles.title}>{header}</div>}
        <div className={`buttons ${styles['button-group']}`}>
          {sectionName === 'brands' ? (
            <Button
              onClick={onBrandsMoreClick}
              hierarchy={ButtonHierarchy.Outline}
              size={Size.Small}
              label="More"
            />
          ) : sectionName === 'snaps' ? (
            <Button
              onClick={onCustomButtonClick}
              hierarchy={ButtonHierarchy.Outline}
              size={Size.Small}
              label="I'm not interested"
            />
          ) : (
            moreLink &&
            moreLabel && (
              <InternalLink
                href={moreLink}
                hierarchy={ButtonHierarchy.Outline}
                size={Size.Small}
                hasPadding
                hasBackground>
                More<span className="sr-only"> {moreLabel}</span>
              </InternalLink>
            )
          )}
          <div className={styles.controls}>
            <Button
              icon="ChevronLeft"
              hierarchy={ButtonHierarchy.Outline}
              size={Size.Small}
              onClick={() => scrollTo(-scrollValue)}
              disabled={isAtStart}
            />
            <Button
              icon="ChevronRight"
              hierarchy={ButtonHierarchy.Outline}
              size={Size.Small}
              onClick={() => scrollTo(scrollValue)}
              disabled={isAtEnd}
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.carousel} ${carouselClass}`}
        onScroll={handleScroll}
        ref={scrollContainerRef as RefObject<HTMLDivElement>}>
        {children}
      </div>
    </section>
  )
}
